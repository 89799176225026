import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import Layout from "../components/layout/layout"
import Button from "../components/Button/Button"
import CTA from "../components/CTA/CTA"
import Form from "../components/Form/Form"
import Title from "../components/Title/Title"
import Underline from "../components/Underline/Underline"
import MetaImage from "../images/paula-1.jpeg"
import * as AppStyles from "./appointments.module.css"

export default function Appointments({ data }) {
    const content = data.allContentYaml.edges[0].node

    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Appointments | Paula Abbey Counselling & Psychotherapy</title>
                <meta name="title" content="Appointments | Paula Abbey Counselling & Psychotherapy" />
                <meta name="description" content="Arrange an initial free 30 minute call, without obligation. I always start with this call because it's so important to find a therapist that you like and feel comfortable with." />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://paulaabbey.co.uk/appointments/" />
                <meta property="og:title" content="Appointments | Paula Abbey Counselling & Psychotherapy" />
                <meta property="og:description" content="Arrange an initial free 30 minute call, without obligation. I always start with this call because it's so important to find a therapist that you like and feel comfortable with." />
                <meta property="og:image" content={MetaImage} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://paulaabbey.co.uk/appointments/" />
                <meta name="twitter:title" content="Appointments | Paula Abbey Counselling & Psychotherapy" />
                <meta name="twitter:description" content="Arrange an initial free 30 minute call, without obligation. I always start with this call because it's so important to find a therapist that you like and feel comfortable with." />
                <meta name="twitter:image" content={MetaImage} />
            </Helmet>
            <Title>
                <h1>Appointments</h1>
            </Title>
            <section className={AppStyles.formSection}>
                <div className={AppStyles.formIntro}>
                    <Underline>
                        <h2>Get in touch</h2>
                    </Underline>
                    <p>For any questions or to arrange an appointment, please fill out the form and I'll respond to you as soon as possible.</p>
                    <p className={AppStyles.callout}>Arrange an initial 20 minute call, without obligation.</p>
                    <div className={AppStyles.contact}>
                        <h3>Contact details</h3>
                        <div><FontAwesomeIcon icon={faPhone} /><a href="tel:07898437229">07898 437 229</a></div>
                        <div><FontAwesomeIcon icon={faEnvelope} /><a href="mailto:hello@paulaabbey.co.uk">hello@paulaabbey.co.uk</a></div>
                    </div>
                    <div className={AppStyles.times}>
                        <h3>Appointment times</h3>
                        <ul>
                            {
                                content.availability.map(hours => {
                                    return (
                                        <li>{hours}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <Form />
            </section>
            <section className={AppStyles.cta}>
                <CTA>
                    <h2>Questions before getting started?</h2>
                    <Button type="internal" colour="secondary" to="/faq/">Visit Frequenly Asked Questions</Button>
                </CTA>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        allContentYaml {
            edges {
                node {
                    availability
                }
            }
        }
    }
`