import React from "react"
import * as FormStyles from "./Form.module.css"

function Form() {
    return (
        <div className={FormStyles.container}>
            <form method="post" action="/success/" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <div>
                    <label htmlFor="name">Full name</label><br />
                    <input type="text" name="name" id="name" required />
                </div>
                <div>
                    <label htmlFor="email">Email address</label><br />
                    <input type="email" name="email" id="email" required />
                </div>
                <div>
                    <span className={FormStyles.labels}><label htmlFor="tel">Telephone number</label><label htmlFor="tel">(Optional)</label></span>
                    <input type="tel" name="tel" id="tel" />
                    <small>Your phone number is kept confidential and not shared with others</small>
                </div>
                <div>
                    <label htmlFor="message">Message</label><br />
                    <textarea id="message" name="message" rows="8" cols="10" placeholder="Type your message here..." required></textarea>
                </div>
                <input type="submit" value="Send message" />
            </form>
        </div>
    )
}

export default Form